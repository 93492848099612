"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const locale = {
  placeholder: '请选择时间',
  rangePlaceholder: ['开始时间', '结束时间']
};
var _default = exports.default = locale;