import { useState, useEffect } from 'react';
import { arrayMoveImmutable } from 'array-move';
import useActiveTab from '@/hooks/useActiveTab';
import { objectToFormdata } from '@/utils';
import { COLLECTION_TYPES } from '@/utils/constants';
import api from '@/service/api';

export const initialValues = {
  fetching: false,
  activeTab: COLLECTION_TYPES.public,
  modal: { open: false, data: null, collectable_type: '' },
  collections: { loading: false, data: [] },
  collection: null,
};

export default function Page() {
  const [fetching, setFetching] = useState(initialValues.fetching);
  const [activeTab, setActiveTab] = useActiveTab(
    initialValues.activeTab,
    'collectionsTab',
  );
  const [modal, setModal] = useState<{
    open: boolean;
    data: null | Record<string, any>;
    collectable_type: string;
  }>(initialValues.modal);
  const closeModal = () => setModal(initialValues.modal);

  const [collections, setCollections] = useState<{
    loading: boolean;
    data: any[];
  }>(initialValues.collections);
  const [collection, setCollection] = useState<Record<string, any> | null>(
    initialValues.collection,
  );

  useEffect(() => {
    setCollection(null);
  }, [activeTab]);

  const initCollectModel = () => {
    setFetching(initialValues.fetching);
    closeModal();
    setCollection(initialValues.collection);
  };

  const fetchCollectionsList = async () => {
    setCollections((pre) => ({ ...pre, loading: true }));
    const result: any = await api.collections.list({ tab: activeTab });
    if (Array.isArray(result?.collections)) {
      setCollections((pre) => ({
        ...pre,
        data: result.collections,
        total: 0,
      }));
    }
    setCollections((pre) => ({ ...pre, loading: false }));
  };

  // Actions
  const success = async () => {
    closeModal();
    await fetchCollectionsList();
  };

  const fetchOptionalItems = async (id: string) => {
    const result: any = await api.collections.optionalItems(id);
    if (Array.isArray(result?.items) && result.items.length > 0) {
      return result.items;
    }
    return [];
  };

  // 获取合集
  const getCollection = async (id: string) => {
    if (!id) return;
    setFetching(true);
    const res: any = await api.collections.detail(id);
    if (res?.collection) {
      const { id } = res.collection;
      const optionalItems = await fetchOptionalItems(id);
      setCollection({ ...(res.collection || {}), optionalItems });
    } else {
      setCollection(null);
    }
    setFetching(false);
  };

  // 创建合集
  const createCollection = async (newCollection: Record<string, any>) => {
    await api.collections.create(
      objectToFormdata({ collection: newCollection }),
    );
    await success();
  };

  // 更新合集
  const updateCollection = async (
    collectionId: string,
    newCollection: Record<string, any>,
  ) => {
    const params = objectToFormdata({ collection: newCollection });
    await api.collections.update(collectionId, params);
    await success();
    if (collectionId == collection?.id) {
      await getCollection(collectionId);
    }
  };

  // 上架合集
  const publishCollection = async (collectionId: string) => {
    await api.collections.publish(collectionId);
  };

  // 下架合集
  const unPublishCollection = async (collectionId: string) => {
    await api.collections.unpublish(collectionId);
  };

  // 删除合集
  const destroyCollection = async (collectionId: string) => {
    await api.collections.destroy(collectionId);
    if (collectionId == collection?.id) {
      setCollection(null);
    }
  };

  // 添加一个 collect
  const collectProduct = async (
    collection_id: string,
    collectable_id: string,
    collectable_type: string,
  ) => {
    const params = { collection_id, collectable_id, collectable_type };
    await api.collects.create(params);
    await getCollection(collection_id);
  };

  // 移动一个 collect
  const moveCollect = async (
    collect_id: string,
    newIndex: number,
    oldIndex: number,
  ) => {
    setCollection({
      ...collection,
      collects: arrayMoveImmutable(collection?.collects, oldIndex, newIndex),
    });
    await api.collects.move(collect_id, newIndex + 1);
    await getCollection(collection?.id);
  };

  // 删除一个 collect
  const destroyCollect = async (collect_id: string) => {
    await api.collects.destroy(collect_id);
    await getCollection(collection?.id);
  };

  return {
    data: {
      fetching,
      activeTab,
      modal,
      collections,
      collection,
    },
    methods: {
      setActiveTab,
      setModal,
      closeModal,
      fetchCollectionsList,
      getCollection,
      createCollection,
      updateCollection,
      publishCollection,
      unPublishCollection,
      destroyCollection,
      collectProduct,
      moveCollect,
      destroyCollect,
      initCollectModel,
    },
  };
}
