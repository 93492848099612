import { useState, useMemo } from 'react';
import api from '@/service/api';
import useGallery from '../hooks/useGallery';

type Data = { [key: string]: any };

export type DetailType = {
  loading: boolean;
  data: null | Data;
  productId: number | string | null;
};

const initDetail: DetailType = { loading: false, data: null, productId: null };

export enum PRODUCT_LIST_KEY {
  basic = 'basic',
  image = 'image',
  variants = 'variants',
}

function Product() {
  const [activeTab, setActiveTab] = useState<PRODUCT_LIST_KEY>(
    PRODUCT_LIST_KEY['variants'],
  );
  const [currentVariant, setCurrentVariant] = useState<any>(); // 当前选中的variant

  const [detail, setDetail] = useState<DetailType>({ ...initDetail });
  const { gallery, setProduct } = useGallery();

  const changeDetail = (v: Partial<DetailType> = {}) => {
    setDetail((pre) => ({ ...pre, ...v }));
  };

  const resetDetail = () => changeDetail({ ...initDetail });

  const fetchProduct = async (productId: string | undefined) => {
    if (!productId) return;
    changeDetail({ loading: true });
    try {
      const result: Data = await api.products.detail(productId);
      if (!!result) {
        changeDetail({ productId: result.id, data: result });
        setProduct(result);
      }
    } catch (error) {
      console.log(error);
    }
    changeDetail({ loading: false });
  };

  const productExists = useMemo(
    () => !!detail.productId && !!detail.data,
    [detail],
  );

  const productName = useMemo(() => detail?.data?.title, [detail]);

  return {
    activeTab: activeTab as PRODUCT_LIST_KEY,
    detail,
    productExists,
    productName,
    methods: {
      setActiveTab: setActiveTab as (key: PRODUCT_LIST_KEY) => void,
      setDetail,
      changeDetail,
      resetDetail,
      fetchProduct,
    },
    setCurrentVariant,
    currentVariant,
    gallery,
  };
}

export default Product;
