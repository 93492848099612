import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const PageLoading: React.FC = () => (
  <div className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center">
    <Spin
      spinning
      size="large"
      indicator={<LoadingOutlined className="text-primary" />}
    />
  </div>
);

export default PageLoading;
