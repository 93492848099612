import { useEffect, useState } from 'react';

const getDevInitialValues = () =>
  localStorage.getItem('developer_mode') || false;

export default () => {
  const [dev, setDev] = useState<string | boolean>(getDevInitialValues());

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const devParam = urlParams.get('dev');
    if (devParam !== null) {
      const dev = ['true', 'on', '1'].includes(devParam);
      setDev(dev);
      localStorage.setItem('developer_mode', (dev || '') as string);
    }
  }, []);

  return { isDeveloperMode: !!dev };
};
