import { useState } from 'react';
import { history } from 'umi';

const getLogin = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    history.replace('/users/sign_in');
    localStorage.clear();
    return null;
  }
  const user = localStorage.getItem('user');
  if (!user) {
    history.replace('/users/sign_in');
    localStorage.clear();
    return null;
  }
  return { user: JSON.parse(user), token };
};

export default () => {
  const [login, setLogin] = useState(getLogin());

  /**
   * 权限判断函数
   * @param {string[]} auth
   * @returns {boolean}
   */
  const isAuth = (auth: string[] = []) => {
    if (!login) return false;
    if (Array.isArray(auth)) {
      return auth.length === 0
        ? true
        : auth.some((v) => login.user.roles.includes(v));
    }
    return false;
  };

  return { login, setLogin, isAuth };
};
